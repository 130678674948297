<template>
  <application-form-preview view-mode="champion" />
</template>

<script>
import ApplicationFormPreview from "@/views/components/forms/applications/ApplicationFormPreview.vue";
export default {
  components: {
    ApplicationFormPreview,
  },
};
</script>